let key = {};
if (process.env.NODE_ENV === "production") {
	console.log("Set Production Config");
	key = {
                

                baseUrl :"https://nftapi.spideybarter.com",
                frontUrl:"https://www.spideybarter.com/",
                BNBNetwork:56,
                ETHNetwork:1,
                decimal:1000000000000000000,
                Networkname:"Testnet smart chain or Ropstan network",
                AdminAddress:"",
                USDTContract:"",
                SwappairAddress:"",
                EthtoUsdAddress:"",
                Rpcurl:"",
	};
} else {
	console.log("Set Development Config");
	key = {
           baseUrl :"https://nftapi.spideybarter.com",
                frontUrl:"http://localhost:3003",
                BNBNetwork:97,
                ETHNetwork:3,
                decimal:1000000000000000000,
                Networkname:"Testnet smart chain or Ropstan network",
                AdminAddress:"0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7",
                USDTContract:"0xf50226a7c0653fa4C4b67a7ae7ab018862BFa317",
                SwappairAddress:"0xcE37B50B2538C648a251AdC48f290b80F6bc1C2c",
                EthtoUsdAddress:"0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
                Rpcurl:"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",

	};
}

export default key;

