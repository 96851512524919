/*eslint-disable*/
import React, {useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";
import{gethomesettings} from "../../actions/users"
const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const [link,setlink]=useState({})
  const getsettings= async()=>{
    var linkdata =  await gethomesettings()
 
    
    
   if(linkdata&&linkdata.userValue&&linkdata.userValue.data){
    setlink(linkdata.userValue.data)
   }
  }
   useEffect(() => {
    getsettings()
   },[])
  
  return (
    <footer className={footerClasses + " footer_inner"}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem sm={12} md={12} lg={12}>
          
            <div className="footerLinks">
          
              <div className="footerLinks_left">
                <ul>
           
                  <li><Link to="/faq">FAQ's</Link></li>
                  <li><Link to="/terms-and-condition">Terms & Conditions</Link></li>
                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                </ul>
                <p><i class="far fa-copyright"></i> Copyright 2023 by SpideyBarter. All rights reserved.</p>
              </div>
              <div className="footerLinks_right">
                <ul className="social_links">
                  <li><a href={link.linkedin} target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                  <li><a href={link.facebook} target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href={link.twitter} target="_blank"><i class="fab fa-twitter"></i></a></li>
                  <li><a href={link.youtube} target="_blank"><i class="fab fa-youtube"></i></a></li>
                  <li><a href={link.medium} target="_blank"><i class="fab fa-medium"></i></a></li>
                  <li><a href={link.twitch}><i class="fab fa-twitch"></i></a></li>
                  <li><a href={link.github}><i class="fab fa-github"></i></a></li>
                </ul>
              </div> 
             </div>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
