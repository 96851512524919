import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {getFaqList} from '../actions/users'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AboutUs(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');
  const [faqData,setfaqData]=useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{

    getFaqData();

  },[])

  const getFaqData=async ()=>{

    const {faqData}=await getFaqList();
    setfaqData(faqData);


  }

  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="m-auto">
              <h1 className="main_title main_title_center text-center mb-5 mt-5">Privacy Policy</h1>
              <div className="content_panel mt-5">
                  <h3>This privacy notice discloses the privacy practices and applies solely to information collected by this website. It will notify you of the following:</h3>
              
                {/* <h3>Ready to Solve Scam Issues and Get Back your Money</h3> */}
                <p>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</p>
                <p>What choices are available to you regarding the use of your data.
The security procedures in place to protect the misuse of your information.</p>
                <p>How you can correct any inaccuracies in the information.</p>
                <p>Information Collection, Use, and Sharing
We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p>
<p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g. to ship an order.</p>
<p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.
</p>
                <h3 className="mt-4">Your Access to and Control Over Information
You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</h3>
                <p>See what data we have about you, if any.</p>
                <p>Change/correct any data we have about you.</p>
                <p>Have us delete any data we have about you.</p>
                <p>Express any concern you have about our use of your data.</p>
                <h3 className="mt-4">Security</h3>
                <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
                <p>Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the Web page.</p>
                <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>
                <Button className="banner_btn mt-3"><Link to="/home"><i class="bi bi-arrow-left mr-2"></i> Back</Link></Button>
              </div>
            </GridItem>
          </GridContainer>
         
        </div>
      </div>
      <Footer/>
    </div>
  );
}
