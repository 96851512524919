import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import 'react-toastify/dist/ReactToastify.css';
import {donationAdd } from '../actions/users';
import axios from "axios";
import config from '../actions/config';
import isEmpty from '../lib/isEmpty';
import Web3 from 'web3';
import ABI from '../ABI/ABI.json'
import Validator from "validator";
const baseurl    =  config.baseUrl;
var BNBNetwork   = config.BNBNetwork;
var ETHNetwork   = config.ETHNetwork;
var Networkname  = config.Networkname;
var decimals     = config.decimal;
var AdminAddress = config.AdminAddress;
var USDTContract = config.USDTContract;

const initialFormValue = {
  "project": "",
  "airdrop":"",
  "giveaway":"",
  "donation":"",
  

}
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DonateContributors(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history                       = useHistory();
  const [formValue, setFormValue]     = useState(initialFormValue);
  const [blockchange, setBlockchain]  = useState("");
  const [currencyval,setCurrency]        = useState("");
  const [errorValue, seterrorValue]   = useState({});
  const [convertethval,setETHval]     = useState(0);
  const [convertbnbval,setBNBval]     = useState(0);
  const [Save_CallStatus,Set_Save_CallStatus]   = useState("init");

    // const blockchainchange = (e) => {
    //   setBlockchain(e.target.value)
    // }
    // const currencychange =(e) =>{
    //   setCurrency(e.target.value)
    // }
    const onChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        setCurrency(e.target.value)
        setBlockchain(e.target.value)
        let formData = { ...formValue, ...{ [id]: value } }
        setFormValue(formData)
    }
    function Validation(){
// alert(blockchange)
console.log("formValue:",formValue)
      var errors = {};
      var data ={};
      var isFormValid=true;
     
      data.project         = !isEmpty(formValue.project) ? formValue.project : "";
      data.giveaway        = !isEmpty(formValue.giveaway) ? formValue.giveaway : "";
      data.airdrop         = !isEmpty(formValue.airdrop) ? formValue.airdrop : "";
      data.blockchange     = !isEmpty(formValue.blockchainchange) ? formValue.blockchainchange : "";
      data.currency        = !isEmpty(formValue.currency) ? formValue.currency : "";
      data.donation        = !isEmpty(formValue.donation) ? formValue.donation : "";
      
      if (Validator.isEmpty(data.project)) {
        errors.project = "Project field is required";
        isFormValid=false;
    } 
    if (Validator.isEmpty(data.airdrop)) {
        errors.airdrop = "Air drop field is required";
        isFormValid=false;
    }else if(isNaN(data.airdrop) == true) {
      errors.airdrop = '"Air drop" must be a number';
      isFormValid=false;
    }
    if (Validator.isEmpty(data.giveaway)) {
        errors.giveaway = "Give away field is required";
        isFormValid=false;
    }else if(isNaN(data.giveaway) == true) {
      errors.giveaway = '"Give away " must be a number';
      isFormValid=false;
    }
    if (Validator.isEmpty(data.blockchange)) {
        errors.blockchange = "Blockchange field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.currency)) {
        errors.currency = "Currency field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.donation)) {
      errors.donation = "Donation amount field is required";
      isFormValid=false;
  }else if(isNaN(data.donation) == true) {
    errors.donation = '"Donation amount" must be a number';
    isFormValid=false;
  }
      seterrorValue(errors);
      return isFormValid;
    }
      const {project,airdrop,giveaway,donation} = formValue
      const handleSubmit = async () => {
        if(Validation()){
        if(window.ethereum){
        var web3 = new Web3(window.ethereum);
        window.ethereum.enable();
        var currVersion = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        var currAddr = result[0];
        var getBalance  = await web3.eth.getBalance(currAddr);
        var balance = getBalance/decimals;
        var amount = parseFloat(formValue.donation)*parseFloat(decimals);
        var txid = "";
  
        if(formValue.currency=="bnb"){
          if(currVersion!=BNBNetwork){
            toastAlert('error',"Pleace connect Binance Mainnet",'balance');
            return false;
          }
          if(balance<formValue.donation){
            toastAlert('error',"Insufficient balance",'balance');
            return false;
          }
          Set_Save_CallStatus('processing');
          await web3.eth.sendTransaction({
            from: currAddr,
            to: AdminAddress,
            value: amount.toString()
          }).then(async (result) => {
            txid = result.transactionHash;
            Set_Save_CallStatus('done');
          })
          .catch((error) => {
              toastAlert('error',"Donation  rejected",'error')
              setTimeout(() => { window.location.reload(false); }, 2000);
          })

        }else if(formValue.currency=="eth"){
          if(currVersion!=ETHNetwork){
            toastAlert('error',"Pleace connect ETH Mainnet",'balance');
            return false;
          }
          if(balance<formValue.donation){
            toastAlert('error',"Insufficient balance",'balance');
            return false;
          }
          Set_Save_CallStatus('processing');
          await web3.eth.sendTransaction({
            from: currAddr,
            to: AdminAddress,
            value: amount.toString()
          }).then(async (result) => {
            txid = result.transactionHash;
            Set_Save_CallStatus('done');
          })
          .catch((error) => {
              toastAlert('error',"Donation  rejected",'error')
              setTimeout(() => { window.location.reload(false); }, 2000);
          })

        }else if(formValue.currency=="usdt"){
          if(currVersion!=ETHNetwork){
            toastAlert('error',"Pleace connect ETH Mainnet",'balance');
            return false;
          }
          var ContractMethod = new web3.eth.Contract(ABI,USDTContract);
          var balance=await ContractMethod.methods.balances(currAddr).call();
          if(balance==0){
            toastAlert('error',"Insufficient balance",'success');
            return false;
          }
          Set_Save_CallStatus('processing');
          await ContractMethod.methods
          .transfer(AdminAddress,amount.toString())
          .send({
              from: currAddr
           }).then(async (result) => {
              txid = result.transactionHash;
              Set_Save_CallStatus('done');
           })
           .catch((error) => {
            toastAlert('error',"Donation rejected",'error')
            setTimeout(() => { window.location.reload(false); }, 2000);
        })
        }
        if(txid && txid !=""){
          var currency=formValue.currency;
          let formData = { ...formValue, ...{ 'txid': txid ,"blockchange":blockchange,'currencyval':currencyval,'currAddr':currAddr} }
          const {error} = await donationAdd(formData);
          if (isEmpty(error)) {
            Set_Save_CallStatus('processing');
            seterrorValue("");
            setTimeout(() => {
              toastAlert('success',"Donation has beed added successfully",'success')
              Set_Save_CallStatus('done');
            },2000)
            setTimeout(() => {
              history.push("/home")            // window.location.reload()
            },3000)
          }else{
            seterrorValue(error)
          }
        }
    }else{
      toastAlert('error',"Please login into metamask",'success');
    }
  }
  
   }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} className="m-auto">
              <form className="inner_form" >
                <h1 className="main_title main_title_center text-center mb-5 mt-5">Donate/ Contributers</h1>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label">Blockchain</label>
                    <select class="custom-select primary_inp" id="blockchainchange" onChange={onChange}>
                      <option value="">Select</option>
                      <option value="bep20">BEP20</option>
                      <option value="erc">ERC</option>
                      <option value="trx">TRX</option>
                    </select>
                    <span style={{color:"red"}}>{errorValue.blockchange}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Project</label>
                    <input type="text" className="primary_inp" id="project" onChange={onChange}/>
                 <span style={{color:"red"}}>{errorValue.project}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Air Drop <span>(Go to Liquidity Fund)</span></label>
                    <input type="text" className="primary_inp" id="airdrop" onChange={onChange}/>
                       <span style={{color:"red"}}>{errorValue.airdrop}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Give Away <span>(Go to Liquidity Fund)</span></label>
                    <input type="text" className="primary_inp" id="giveaway" onChange={onChange}/>
                <span style={{color:"red"}}>{errorValue.giveaway}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Deposit what we have <span>(send 20% value ETH/BNB to Get 200 APY)</span></label>
                     <select class="custom-select primary_inp" id="currency" onChange={onChange} >
                      <option value="">Select</option>
                      <option value="eth">ETH</option>
                      <option value="bnb">BNB</option>
                      <option value="usdt">USDT</option>
                    </select>
                   <span style={{color:"red"}}>{errorValue.currency}</span>
                   
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Donation <span>(Go to Liquidity Fund)</span></label>
                    <input type="text" className="primary_inp" id="donation" onChange={onChange}/>
                 <span style={{color:"red"}}>{errorValue.donation}</span>
                  </div>
            
                </div>
                <div className="text-center mb-3">
                <Button
                    className="banner_btn "
                    disabled={( Save_CallStatus=='processing' || Save_CallStatus=='done')}
                    onClick={() => handleSubmit()}
                    >
                        {Save_CallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {Save_CallStatus == 'init' && 'Submit'}
                        {Save_CallStatus == 'processing' && 'In-progress...'}
                        {Save_CallStatus == 'done' && 'Done'}
                        {Save_CallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
