import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import 'react-toastify/dist/ReactToastify.css';
import {savescancompany } from '../actions/users';
import Validator from "validator";
import isEmpty from '../lib/isEmpty';
import Web3 from 'web3';
const { web3 }         = window;
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  "name": "",
  "totalcoin":"",
  "value":"",
  "usertotal":"",
  "fundraise":""

}

export default function ScamCompany(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history                       = useHistory();
  const [formValue, setFormValue]     = useState(initialFormValue);
  const [blockchange, setBlockchain]  = useState("");
  const [errorValue, seterrorValue]   = useState({});
  const [Save_CallStatus,Set_Save_CallStatus]   = useState("init");
  
  const blockchainchange = (e) => {
   setBlockchain(e.target.value)
  }
  const onChange = (e) => {
          e.preventDefault();
          const { id, value } = e.target;
          let formData = { ...formValue, ...{ [id]: value } }
          setFormValue(formData)
    }
     const {
      name,
      totalcoin,
      value,
      usertotal,
      fundraise
    } = formValue
    function Validation(){

      var errors = {};
      var data ={};
      var isFormValid=true;
      data.name         = !isEmpty(formValue.name) ? formValue.name : "";
      data.totalcoin = !isEmpty(formValue.totalcoin) ? formValue.totalcoin : "";
      data.value     = !isEmpty(formValue.value) ? formValue.value : "";
      data.usertotal     = !isEmpty(formValue.usertotal) ? formValue.usertotal : "";
      data.fundraise        = !isEmpty(formValue.fundraise) ? formValue.fundraise : "";
      data.blockchange        = !isEmpty(formValue.blockchange) ? formValue.blockchange : "";
      
      if (Validator.isEmpty(data.name)) {
          errors.name = "Company/ Project Name field is required";
          isFormValid=false;
      } 
      if (Validator.isEmpty(data.totalcoin)) {
          errors.totalcoin = "Total Coin field is required";
          isFormValid=false;
      }
      if (Validator.isEmpty(data.value)) {
          errors.value = "Value field is required";
          isFormValid=false;
      }
      if (Validator.isEmpty(data.usertotal)) {
          errors.usertotal = "How much User Total field is required";
          isFormValid=false;
      }
      if (Validator.isEmpty(data.fundraise)) {
          errors.fundraise = "How much Fund Raise field is required";
          isFormValid=false;
      }
      if (Validator.isEmpty(blockchange)) {
          errors.blockchange = "Blockchain field is required";
          isFormValid=false;
      }else if (blockchange==undefined) {
        errors.blockchange = "Blockchain field is required";
        isFormValid=false;
      }
      seterrorValue(errors);
      return isFormValid;
    }
    const handleSubmit = async () => {
      if(Validation()){
        if(window.ethereum){
          var web3 = new Web3(window.ethereum);
          window.ethereum.enable();
          var currVersion = await web3.eth.net.getId();
          var result = await web3.eth.getAccounts();
          var currAddr = result[0];
     let reqData={name,totalcoin,value,usertotal,fundraise,blockchange,currAddr}
      const {error} =await savescancompany(reqData);
      console.log("error:",error)
      if (isEmpty(error)) {
        Set_Save_CallStatus('processing');
        seterrorValue("");
        setTimeout(() => {
          toastAlert('success',"Scam company has beed added successfully",'success')
          Set_Save_CallStatus('done');
        }, 2000)
        setTimeout(() => {
          history.push("/home")            // window.location.reload()
        }, 3000);
      }else{
      seterrorValue(error)
    }
  }
}
   }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} className="m-auto">
              <form className="inner_form" >
                <h1 className="main_title main_title_center text-center mb-5 mt-5">Scam Company</h1>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label">Blockchain</label>
                    <select class="custom-select primary_inp" onChange={blockchainchange}>
                      <option value="">Select</option>
                      <option value="bep20">BEP20</option>
                      <option value="1eth">ERC</option>
                      <option value="trx">TRX</option>
                    </select>
                     <span style={{color:"red"}}>{errorValue.blockchange}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Company/ Project Name</label>
                    <input type="text" className="primary_inp" id="name" onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.name}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Total Coin</label>
                    <input type="number" className="primary_inp" id="totalcoin" onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.totalcoin}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Value</label>
                    <input type="number" className="primary_inp" id="value" onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.value}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">How much User Total</label>
                    <input type="number" className="primary_inp" id="usertotal" onChange={onChange}/>
                  <span style={{color:"red"}}>{errorValue.usertotal}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">How much Fund Raise</label>
                    <input type="number" className="primary_inp" id="fundraise" onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.fundraise}</span>
                  </div>
                </div>
                <div className="text-center mb-3">
                <Button
                    className="banner_btn "
                    disabled={( Save_CallStatus=='processing' || Save_CallStatus=='done')}
                    onClick={() => handleSubmit()}
                    >
                        {Save_CallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {Save_CallStatus == 'init' && 'Submit'}
                        {Save_CallStatus == 'processing' && 'In-progress...'}
                        {Save_CallStatus == 'done' && 'Done'}
                        {Save_CallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                  
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
