import React, { useEffect,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import config from '../actions/config';
import isEmpty from '../lib/isEmpty';
import Web3 from 'web3';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart';
import Validator from "validator";
import {feedback } from '../actions/users';
const { web3 }         = window;
const baseurl          =  config.baseUrl;
console.log("baseUrlL",baseurl)
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  "email": "",
  "contractaddress":"",
  "totcoinuser":"",
  "convertval":""
}


export default function WhoHasCheated(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history                       = useHistory();
  const [formValue, setFormValue]     = useState(initialFormValue);
  const [blockchange, setBlockchain]  = useState("");
  const [currency,setCurrency]        = useState("");
  const [errorValue, seterrorValue]   = useState({});
  const [convertethval,setETHval]     = useState(0);
  const [convertbnbval,setBNBval]     = useState(0);
  const [totalcoin,setTotalcoin]      = useState(0);
  const [converttotalval,setConvertval]= useState(0);
  const [report,setreport]            = React.useState("");
  const [addEmoji,setaddEmoji]        = React.useState("");
  const [emoji,setEmoji]              = React.useState("");
  useEffect(() => {
   getETHvalue();
   getBNBvalue();
  },[]);

  const blockchainchange = (e) => {
  setBlockchain(e.target.value)
  }
  const currencychange =(e) =>{
    setCurrency(e.target.value)
  }
    const onChange = (e) => {
          e.preventDefault();
          const { id, value } = e.target;
          console.log("@@@@@@",value*convertethval/100)
          var adminvalue=10;

           setTotalcoin(value*convertethval/100)
          let formData = { ...formValue, ...{ [id]: value } }
          setFormValue(formData)
    }
    const onChangecoin =(e)=>{
          e.preventDefault();
          const { id, value } = e.target;
          var adminvalue=10;
          if (currency=="bnb") {
              var totalcoin=value*convertbnbval;
              var adminAmt = totalcoin*adminvalue/100;
              setTotalcoin(adminAmt);
          }else if (currency=="eth") {
              var totalcoin=value*convertethval;
              var adminAmt = totalcoin*adminvalue/100;
              setTotalcoin(adminAmt)
          }
          let formData = { ...formValue, ...{ [id]: value } }
          setFormValue(formData)
    }
  const {
      email,
      contractaddress,
      totcoinuser,
      convertval
    } = formValue

    async function getETHvalue(){
      axios
          .get('https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH')
          .then(data => {
            setETHval(data.data.ETH);
          })
    }
  async function getBNBvalue(){
     axios
          .get('https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=BNB')
          .then(data => {
            setBNBval(data.data.BNB);
          })
  }
  function Validation(){
    // alert(blockchange)
    console.log("formValue:",formValue)
          var errors = {};
          var data ={};
          var isFormValid=true;
          const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

          data.email         = !isEmpty(formValue.email) ? formValue.email : "";
          data.report        = !isEmpty(formValue.report) ? formValue.report : "";
          
          if (Validator.isEmpty(data.email)) {
            errors.email = "E-mail field is required";
            isFormValid=false;
        }else if(regex.test(data.email) === false){
          errors.email = "E-mail is not valid";
            isFormValid=false;
        } 
        if (Validator.isEmpty(report)) {
            errors.report = "Report field is required";
            isFormValid=false;
        }
        // if (Validator.isEmpty(emoji)) {
        //     errors.emoji = "emoji field is required";
        //     isFormValid=false;
        // }
       
          seterrorValue(errors);
          return isFormValid;
        }
const handleSubmit = async (e) => {
     e.preventDefault();
     if(Validation()){
     let reqData={email,report,emoji}
     console.log("reqData",reqData)
      const {error} =await feedback(reqData);
      console.log("error++++",error)
      if (isEmpty(error)) {
        seterrorValue("");
        toastAlert('success',"Report from has been submitted",'success')
        setTimeout(() => {
            history.push("/home")            // window.location.reload()
            }, 1000)
      }else{
      seterrorValue(error)
    }
  }
  }
  const reportchange=(e)=>{
    setreport(e.target.value)
  }
  const addEmoji1 = (e) => {

      setaddEmoji(e.native)
      setEmoji(e.unified)
    }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} className="m-auto">
              <form className="inner_form" onSubmit={handleSubmit}>
                <h1 className="main_title main_title_center text-center mb-5 mt-5">Feed back form </h1>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label">E-mail</label>
                    <input type="email" className="primary_inp" id="email" onChange={onChange}/>
                 <span style={{color:"red"}}>{errorValue.email}</span>
                  </div>
              <div className="form-group col-md-6">
                    <label className="primary_label">Message</label>
                <textarea className="primary_inp" rows="3" onChange={reportchange} placeholder="Tell us some details"></textarea>
                   <span style={{color:"red"}}>{errorValue.report}</span>
                </div>
                  <div className="form-group col-md-6">
                     <label className="primary_label" htmlFor="name">{addEmoji}</label>
                      <Picker onSelect={addEmoji1} i18n={{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }} />
                  </div>
                </div>
                <div className="text-center mb-3">
                  <Button className="banner_btn" type="submit">Submit</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
