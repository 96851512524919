import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {getFaqList} from '../actions/users'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AboutUs(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');
  const [faqData,setfaqData]=useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{

    getFaqData();

  },[])

  const getFaqData=async ()=>{

    const {faqData}=await getFaqList();
    setfaqData(faqData);


  }

  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="m-auto">
              <h1 className="main_title main_title_center text-center mb-5 mt-5">About Us</h1>
              <div className="content_panel mt-5">
                <h3>Ready to Solve Scam Issues and Get Back your Money</h3>
                <p>Getting to know Binance Smart Chain (BSC) is not as difficult as the traditional Blockchain system that runs the cryptocurrency world. This is a simplified version that helps traders and investors to <b>put their assets back in the game without being really affected by scammers</b>.</p>
                <p>BSC is redeveloping the old ways of trading by providing safer options to those who want to contribute to its massive growth. So, in the current age crypto-followers are able to appreciate how lots of projects have been developed and/or integrated into this main platform that, indeed, comes from a genesis project, Binance itself.</p>
                <p>There is still a lot of pressure in this digital landscape because users worry a lot about the <b>security of their data</b>, and the level of protection that some yet-in-development projects are carrying. It is not a minor issue that is upsetting the entire ecosystem. Nonetheless, there is a group of committed enthusiasts who developed the first non-invasive and institutional network capable of running into the BSC to bring a higher support to the system and help traders to identify those projects that are dead or rarely used, so in the end they won't face scam.</p>
                <p>Spidey Barter is not only an illustrative development yet to be released, but <b>a certified project that is promising great results with already released outcomes in the cryptocurrency market</b>. Since it is part of the <b>Bitflix core</b>, this project is aimed to be a helpful electronic assistant that will fight out all scams, malware's, and other odd functionalities that are conditioned to destroy wilts causing lots.</p>
                <h3 className="mt-4">Why is it said to be the first platform running into the BSC?</h3>
                <p>Spidey Barter is for so long, the first platform really running into the BSC since its features are programmed to the security layer of the main network itself.</p>
                <p>A platform can hold a nice web-view, an electronic smart-contract, and other sorts of sections to hold the rights of the project. However, if it doesn't have a shield activated to represent loss-avoidance it has anything but a continuously affected ecosystem.</p>
                <p>That's why Spidey Barter is there. Even without being totally released, the system provides a 100% of effectivity into the entire platform starting by sending back the rewards and compensations of users that were affected during decades by terrible scammers.</p>
                <p>This is a project where you can safely put your money as it will first will give you money back, just if you were one of those traders that faced terrible results when trading.</p>
                <h3 className="mt-4">An Stressful Labor Reduced by a Competitive Development</h3>
                <p>It is not a secret that sending or receiving compensations for losses can be stressfully, demanding, and full of triggering sides. It's for that reason that when you trust in experts running S-B you'll see who the system catches all the scam into its digital spider-web, gets what doesn't belong to them, and delivers to what has always been yours.</p>
                <p>In the past, the crypto-community was worried about holding the keys of their future. Today, their worry grows when they see that this digital ecosystem is not totally since you don't really know who's behind those platforms that are believed to be legit.</p>
                <p>That's why the image of Spider Barter is transparent. <b>Our principal value is to raise confidence into our users</b>, so they can deposit trust, something that matters more than money.</p>
                <p>As soon as this platform gets integrated into the smart chains of claimed crypto-projects, it will activate security layers through the entire system. Likewise, it will determine the number of users that during years have claimed for their compensations and received no answers, and run a lightning-speed delivering feature to get the lost assets and deposit them into user's accounts.</p>
                <h3 className="mt-4">Using Spider Barter by Following the Simple Three-step Guideline</h3>
                <p>Whether you want to give the platform a try, or you want to go beyond old methods, you are welcome to run Spidey Barter. It is easy to know how it works since the project is actively working in the BSC.</p>
                <ul>
                  <li><b>Reveal the dead project in which you put your money</b>, so the system can track transactions and get a record of all pending rewards.</li>
                  <li><b>Apply for the security layer</b> so you won't be again involved in losses. All transactions are made publicly.</li>
                  <li><b>Wait for confirmation and provide your address</b>. Never give any other data to unknown servers. <b>The system won't ask you for your private keys</b>.</li>
                </ul>
                <p>In the end, you'll discover that working with <b>Spidey Barter is better than walking into the Blockchain world by yourself</b>. Just give it time to rescue your money, the rest will be demonstrated in the path.</p>
                <Button className="banner_btn mt-3"><Link to="/home"><i class="bi bi-arrow-left mr-2"></i> Back</Link></Button>
              </div>
            </GridItem>
          </GridContainer>
         
        </div>
      </div>
      <Footer/>
    </div>
  );
}
