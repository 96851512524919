import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import './index.css';
import { ToastContainer} from "react-toastify";
// pages for this product
import Home from "views/home.js";
import WhoHasCheated from "views/whohascheated.js";
import DonateContributors from "views/donate_contributors.js";
import ScamCompany from "views/scam_company.js";
import Report from "views/report.js";
import FAQ from "views/faq.js";
import AboutUs from "views/about.js";
import PrivacyPolicy from "views/privacy_policy.js";
import TermsandCondition from "views/terms_condition.js";
import Document from "views/document.js";

ReactDOM.render(
  <BrowserRouter basename="/"> 
  <ToastContainer/>
    <Switch>
      <Route path="/docs" component={Document} />
      <Route path="/terms-and-condition" component={TermsandCondition} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/about" component={AboutUs} />
      <Route path="/faq" component={FAQ} />
      <Route path="/feed-back" component={Report} />
      <Route path="/scam_company" component={ScamCompany} />
      <Route path="/donate_contributors" component={DonateContributors} />
      <Route path="/whohascheated" component={WhoHasCheated} />
      <Route path="/home" component={Home} />
      <Route exact path="/*" component={Home}>
        <Redirect to="/home" />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
