import React, { useEffect,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/home.js";
import { Link,useHistory } from "react-router-dom";
import { toastAlert }  from "../actions/toastAlert";
import 'react-toastify/dist/ReactToastify.css';
import {savecheated } from '../actions/users';
import axios from "axios";
import config from '../actions/config';
import isEmpty from '../lib/isEmpty';
import Web3 from 'web3';
import Validator from "validator";

import ABI from '../ABI/ABI.json'
const { web3 }         = window;
const baseurl          =  config.baseUrl;
console.log("baseUrlL",baseurl)
const dashboardRoutes = [];
var BNBNetwork   = config.BNBNetwork;
var ETHNetwork   = config.ETHNetwork;
var Networkname  = config.Networkname;
var decimals     = config.decimal;
var AdminAddress = config.AdminAddress;
var USDTContract = config.USDTContract;

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  "project": "",
  "contractaddress":"",
  "totcoinuser":"",
  "convertval":"",
  "adminAmt":""
}


export default function WhoHasCheated(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history                       = useHistory();
  const [formValue, setFormValue]     = useState(initialFormValue);
  const [blockchange, setBlockchain]  = useState("");
  const [currency,setCurrency]        = useState("");
  const [errorValue, seterrorValue]   = useState({});
  const [convertethval,setETHval]     = useState(0);
  const [convertbnbval,setBNBval]     = useState(0);
  const [totalcoin,setTotalcoin]      = useState(0);
  const [converttotalval,setConvertval]     = useState(0);

  const [Save_CallStatus,Set_Save_CallStatus]   = useState("init");
  const [currentNetwork,setcurrentNetwork]     = useState("");

  useEffect(() => {
   getETHvalue();
   getBNBvalue();
  },[]);

  
  const onChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } }
        setFormValue(formData);
  }
   
  const {
      project,
      contractaddress,
      totcoinuser,
      convertval
    } = formValue

    async function getETHvalue(){
      axios
          .get('https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH')
          .then(data => {
            setETHval(data.data.ETH);
          })
    }
  async function getBNBvalue(){
     axios
          .get('https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=BNB')
          .then(data => {
            setBNBval(data.data.BNB);
          })
  }

  function Validation(){

    var errors = {};
    var data ={};
    var isFormValid=true;
   
    data.project         = !isEmpty(formValue.project) ? formValue.project : "";
    data.contractaddress = !isEmpty(formValue.contractaddress) ? formValue.contractaddress : "";
    data.totcoinuser     = !isEmpty(formValue.totcoinuser) ? formValue.totcoinuser : "";
    data.blockchange     = !isEmpty(formValue.blockchange) ? formValue.blockchange : "";
    data.currency        = !isEmpty(formValue.currency) ? formValue.currency : "";
    data.adminAmt        = !isEmpty(formValue.adminAmt) ? formValue.adminAmt : "";
    
    if (Validator.isEmpty(data.project)) {
        errors.project = "Project field is required";
        isFormValid=false;
    } 
    if (Validator.isEmpty(data.contractaddress)) {
        errors.contractaddress = "Contract address field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.totcoinuser)) {
        errors.totcoinuser = "Total Coin User have field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.blockchange)) {
        errors.blockchange = "Blockchange field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.currency)) {
        errors.currency = "Currency address field is required";
        isFormValid=false;
    }
    if (Validator.isEmpty(data.adminAmt)) {
        errors.adminAmt = "Amount field is required";
        isFormValid=false;
    }else if(isNaN(data.adminAmt) == true) {
      errors.adminAmt = '"Amount" must be a number';
      isFormValid=false;
    }
    seterrorValue(errors);
    return isFormValid;
  }

const handleSubmit = async () => {
  if(Validation()){
  if(window.ethereum){
      var web3 = new Web3(window.ethereum);
      window.ethereum.enable();
      var currVersion = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      var currAddr = result[0];
      var getBalance  = await web3.eth.getBalance(currAddr);
      var balance = getBalance/decimals;

      var amount = parseFloat(formValue.adminAmt)*parseFloat(decimals);
      var txid = "";

        if(formValue.currency=="bnb"){
          if(currVersion!=BNBNetwork){
            toastAlert('error',"Pleace connect Binance Mainnet",'balance');
            return false;
          }
          if(balance<formValue.adminAmt){
            toastAlert('error',"Insufficient balance",'balance');
            return false;
          }
          Set_Save_CallStatus('processing');
          var tx = await web3.eth.sendTransaction({
            from: currAddr,
            to: AdminAddress,
            value: amount.toString()
          }).then(async (result) => {
            txid = result.transactionHash;
            Set_Save_CallStatus('done');
          })
          .catch((error) => {
              toastAlert('error',"Cheated from rejected",'error')
              setTimeout(() => { window.location.reload(false); }, 2000);
          })
      }else if(formValue.currency=="eth"){
          if(currVersion!=ETHNetwork){
            toastAlert('error',"Pleace connect ETH Mainnet",'balance');
            return false;
          }
          if(balance<formValue.adminAmt){
            toastAlert('error',"Insufficient balance",'balance');
            return false;
          }
          Set_Save_CallStatus('processing');
          var tx = await web3.eth.sendTransaction({
            from: currAddr,
            to: AdminAddress,
            value: amount.toString()
          }).then(async (result) => {
            txid = result.transactionHash;
            Set_Save_CallStatus('done');
          })
          .catch((error) => {
              toastAlert('error',"Cheated from rejected",'error')
              setTimeout(() => { window.location.reload(false); }, 2000);
          })
      }else if(formValue.currency=="usdt"){
        if(currVersion!=ETHNetwork){
          toastAlert('error',"Pleace connect ETH Mainnet",'balance');
          return false;
        }
          var ContractMethod = new web3.eth.Contract(ABI,USDTContract);
          var balance=await ContractMethod.methods.balances(currAddr).call();
          if(balance==0){
            toastAlert('error',"Insufficient balance",'success');
            return false;
          }
          Set_Save_CallStatus('processing');
         await ContractMethod.methods
          .transfer(AdminAddress,amount.toString())
          .send({
              from: currAddr
           }).then(async (result) => {
              txid = result.transactionHash;
              Set_Save_CallStatus('done');
           })
           .catch((error) => {
            toastAlert('error',"Cheated form rejected",'error')
            setTimeout(() => { window.location.reload(false); }, 2000);
        })
    }
    if(txid && txid !=""){
      let formData = { ...formValue, ...{ 'txid': txid,'currAddr':currAddr } }
      const {error} =await savecheated(formData);
      if (isEmpty(error)) {
        seterrorValue("");
        setTimeout(() => {
          toastAlert('success',"Cheated from has beed added successfully",'success')
          Set_Save_CallStatus('done');
        },2000)
        setTimeout(() => {
          history.push("/home")            // window.location.reload()
        },3000)
      }else{
        seterrorValue(error)
      }
    }
    // else{
    //   toastAlert('error',"Oops something went wrong.!",'success');
    // }

  }else{
    toastAlert('error',"Please login into metamask",'success');
  }
}
  
  }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} className="m-auto">
              <form className="inner_form">
                <h1 className="main_title main_title_center text-center mb-5 mt-5">Who has been Cheated</h1>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label">Blockchain</label>
                    <select class="custom-select primary_inp" id="blockchange" onChange={onChange} >
                      <option value="">Select</option>
                      <option value="bep20">BEP20</option>
                      <option value="erc">ERC</option>
                      <option value="trx">TRX</option>
                    </select>
                      <span style={{color:"red"}}>{errorValue.blockchange}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Name of Project</label>
                    <input type="text" className="primary_inp" id="project" value={formValue.project} onChange={onChange}/>
                 <span style={{color:"red"}}>{errorValue.project}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Contract Address</label>
                    <input type="text" className="primary_inp" id="contractaddress" value={formValue.contractaddress} onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.contractaddress}</span>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label">Total Coin User have</label>
                    <input type="number" className="primary_inp" id="totcoinuser" value={formValue.totcoinuser} onChange={onChange}/>
                   <span style={{color:"red"}}>{errorValue.totcoinuser}</span>
                  </div>
                 
                  <div className="form-group col-md-6">
                    <label className="primary_label">Deposit what we have <span>(send 20% value ETH/BNB to Get 200 APY)</span></label>
                     <select class="custom-select primary_inp" id="currency" onChange={onChange} >
                      <option value="">Select</option>
                      <option value="eth">ETH</option>
                      <option value="bnb">BNB</option>
                      <option value="usdt">USDT</option>
                    </select>
                   <span style={{color:"red"}}>{errorValue.currency}</span>
                   
                  </div>
                  <div className="form-group col-md-6">
                  <label className="primary_label">Amount</label>
                    <input type="number" className="primary_inp"  id="adminAmt" value={formValue.adminAmt} onChange={onChange} />
                    <span style={{color:"red"}}>{errorValue.adminAmt}</span>
                  </div>
                </div>
                <div className="text-center mb-3">
                 {/*  <Button className="banner_btn" 
                   
                  type="button">Submit</Button>*/}
                  <Button
                    className="banner_btn "
                    disabled={( Save_CallStatus=='processing' || Save_CallStatus=='done')}
                    onClick={() => handleSubmit()}
                    >
                        {Save_CallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {Save_CallStatus == 'init' && 'Submit'}
                        {Save_CallStatus == 'processing' && 'In-progress...'}
                        {Save_CallStatus == 'done' && 'Done'}
                        {Save_CallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
