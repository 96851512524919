import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {getFaqList} from '../actions/users'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #17a0bb;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #17a0bb;
    font-size: 32px;
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function FAQ(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');
  const [faqData,setfaqData]=useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{

    getFaqData();

  },[])

  const getFaqData=async ()=>{

    const {faqData}=await getFaqList();
    if (faqData) {
      setfaqData(faqData);
    }
  }

  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="text-center m-auto">
              <h1 className="main_title main_title_center text-center mb-5 mt-5">FAQ</h1>
              <div className="faq_panel mt-5">

                {
                  faqData&&faqData.map((data,i)=>{

                    return(
                      <Accordion expanded={expanded === 'panel'+(i+1)} onChange={handleChangeFaq('panel'+(i+1))}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <h2 className="accordian_head">{data.question}</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">{data.answer}.</p>
                      </AccordionDetails>
                    </Accordion>
                    )

                  })
                }
             
                {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <h2 className="accordian_head">Is there any fee to claim for compensation?</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">There is no fee for the compensation. Where we believe you have a case for claiming compensation, they can be able to act for you on a no fee basis, no win, erasing any financial risk for the process of making a compensation.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                    <h2 className="accordian_head">How to apply for compensation?</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">You can use their secure and simple online claim form to share the detailed information of your situation and they will get back to you shortly.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                  <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                    <h2 className="accordian_head">Is it a transparent platform?</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="accordian_para">Yeah, Spidey Barter is developed on a smart contract so it is a 100% transparent platform. The smart contract is designed by using blockchain technology and smart contract technology.</p>
                  </AccordionDetails>
                </Accordion> */}
               
              </div>
            </GridItem>
          </GridContainer>
         
        </div>
      </div>
      <Footer/>
    </div>
  );
}
