/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { Link } from 'react-scroll'
import config from '../../actions/config';
const useStyles = makeStyles(styles);
const frontUrl          =  config.frontUrl;
export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <div className="top_navbar">
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Link to="home" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Home</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <a href="https://exchange.spideybarter.com/" target="_blank" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Exchange</a>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="about" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">About Us</Link>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Link to="features" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Features</Link>
        </ListItem>

        {/*<ListItem className={classes.listItem}>
          <Link to="roadmap" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Roadmap</Link>
  </ListItem>

        <ListItem className={classes.listItem}>
          <Link to="token" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Tokenomics</Link>
        </ListItem>*/}

        <ListItem className={classes.listItem}>
          <a href="/feed-back" color="transparent" className="nav-link">Feed back</a>
        </ListItem>

        <ListItem className={classes.listItem}>
          <a href="/docs" color="transparent" className="nav-link">Docs</a>
        </ListItem>
        
        {/* <ListItem className={classes.listItem}>
          <Link to="/contact" spy={true} smooth={true} duration={250} offset={-100} color="transparent" className="nav-link">Contact</Link>
        </ListItem>
        */}

      </List>
    </div>
  );
}
