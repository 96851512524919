// import lib
import isEmpty from '../lib/isEmpty'
const validation = value => {
    console.log(value,'vall')
    let errors = {},
        emailRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
if(isEmpty(value.name)){
    errors.name = "Name is Required"
}
    if (isEmpty(value.email)) {
        errors.email = "Email is Required"
    } else if (!(emailRegex.test(value.email))) {
        errors.email = "Invalid email"
    }

    if (isEmpty(value.messages)) {
        errors.messages = " Message field is  Required"
    }

    return errors;
}

export default validation;