import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "styled-components";

import styles from "assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import {getFaqList} from '../actions/users'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AboutUs(props) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState('panel1');
  const [faqData,setfaqData]=useState([]);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=>{

    getFaqData();

  },[])

  const getFaqData=async ()=>{

    const {faqData}=await getFaqList();
    setfaqData(faqData);


  }

  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 100,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className="m-auto">
              <h1 className="main_title main_title_center text-center mb-5 mt-5">Terms & Conditions</h1>
              <div className="content_panel mt-5">
                  <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern our relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
</p>
<p>The term 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.
</p>
    
                <h3 className="mt-4">The use of this website is subject to the following terms of use:
</h3>
              <p>
The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
<p>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties.</p>
<p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>
<p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p>
<p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
</p>
<p>All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</p>
<p>Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
</p>
<p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).


</p>
               <Button className="banner_btn mt-3"><Link to="/home"><i class="bi bi-arrow-left mr-2"></i> Back</Link></Button>
              </div>
            </GridItem>
          </GridContainer>
         
        </div>
      </div>
      <Footer/>
    </div>
  );
}
