import axios from 'axios';
import config from '../actions/config';
import { SET_CURRENT_USER } from '../constant';

export const savecheated = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v1/donation/saverequest`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const feedback = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v1/donation/feedback`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const savescancompany = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v1/donation/savescancompany`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}

export const donationAdd = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v1/donation/donation`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}


export const getFaqList = async () => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/getfaqlist`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            
        });
        return {
            loading: false,
            faqData: respData.data.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const getcmsList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v1/user/getcmslist`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const gethomecms = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v1/user/gethomecms`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const gethomesettings = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v1/user/gethomesettings`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            loading: false,
            userValue: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}
export const addcontact = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v1/user/addcontact`,
            'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
        return {
            status: "success",
            loading: true,
            messages: respData.data.message,
            result: respData
        }
    }
    catch (err) {
        return {
            loading: false,
           error: err.response.data
        }
    }
}