import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { toastAlert }  from "../actions/toastAlert";
import 'react-toastify/dist/ReactToastify.css';
import config from '../actions/config';
import Web3 from 'web3';
import Swappair from '../ABI/Swappair.json'
import EthtoUsdABI from '../ABI/EthtoUsd.json'
import{gethomecms,gethomesettings,addcontact} from "../actions/users"
import moment from 'moment'
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import validation from "./validation";
// import lib
import isEmpty from '../lib/isEmpty'
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  name:"",
  email: "",
  messages: "",
};
export default function LandingPage(props) {
  const history= useHistory();
  const classes = useStyles();
  const [swaptamt,Set_Swapamt] = useState(0);
  const [usdt,Set_Usdamt]      = useState(0);
  const [homeData,sethomeData]=useState('');
  const [homebannerimg,sethomebannerimg]=useState('');
  const [solutionsection,setsolutionsection]=useState('no content')
  const [solutionsectionOne,setsolutionsectionOne]=useState('no content')
  const [solutionsectionTwo,setsolutionsectionTwo]=useState('no content')
  const [solutionsectionThree,setsolutionsectionThree]=useState('no content')
  const [solutionsectionFour,setsolutionsectionFour]=useState('no content')
  const [aboutsectionImage,setaboutsectionImage]=useState('no content')
  const [aboutsectionContent,setaboutsectionContent]=useState('no content')
  const [featuresectionContent,setfeaturesectionContent]=useState('no content')
  const [featurecardOne,setfeaturecardOne]=useState('no content')
  const [featurecardTwo,setfeaturecardTwo]=useState('no content')
  const [featurecardThree,setfeaturecardThree]=useState('no content')
  const [featurecardFour,setfeaturecardFour]=useState('no content')
  const [featurecardFive,setfeaturecardFive]=useState('no content')
  const [featurecardSix,setfeaturecardSix]=useState('no content')
  const [roadmapsection,setroadmapsection]=useState('no content')
  const [roadmapsectionOne,setroadmapsectionOne]=useState('no content')
  const [Tokenomics,setTokenomics]=useState('no content')
  const [TokenomicsOne,setTokenomicsOne]=useState('no content')
  const [TokenomicsTwo,setTokenomicsTwo]=useState('no content')
  const [TokenomicsThree,setTokenomicsThree]=useState('no content')
  const [Getintouch,setGetintouch]=useState('no content')
  const [contact,setContact]=useState('no content')
  const [countdownDate,setcountdownDate]=useState('no content')
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [toched, setToched] = useState({});

  const { name, email, messages } = formValue
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    console.log("hee")
    let reqData = {
      name,
      email,
      messages
    }
    console.log(reqData,'reqData')
    try{
      
      const { status} = await addcontact(reqData);
     if(status == 'success'){
    
      toastAlert('success','Form submitted successfully')
     history.push("/")
      
     }
    }
    catch(err){
console.log(err)
    }
    
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    setValidateError(validation(formData));
    console.log(validateError,'validateError')
  };
  const handleBlur = (e) => {
    const { id } = e.target;
    setToched({ ...toched, ...{ [id]: true } });
  };
  const getdata = async () => {

    var data = await gethomecms();
  
   var res = data.userValue.data
   for(var i in res){
  
  if(res[i].title=='homebanner'){
  
    sethomeData(res[i].content)
  }
  if(res[i].title=='homebannerimg'){

    sethomebannerimg(res[i].content)
  }
  if(res[i].title=='solutionsection'){

    setsolutionsection(res[i].content)
  }
  if(res[i].title=='solutionsection-one'){
    
    setsolutionsectionOne(res[i].content)
  }
  if(res[i].title=='solutionsection-two'){
  
    setsolutionsectionTwo(res[i].content)
  }
  if(res[i].title=='solutionsection-three'){

    setsolutionsectionThree(res[i].content)
  }
  if(res[i].title=='solutionsection-four'){
   
    setsolutionsectionFour(res[i].content)
  }
  if(res[i].title=='aboutsection-image'){
   
    setaboutsectionImage(res[i].content)
  }
  if(res[i].title=='aboutsection-content'){
    
    setaboutsectionContent(res[i].content)
  }
  if(res[i].title=='featuresection'){
   
    setfeaturesectionContent(res[i].content)
  }
  if(res[i].title=='featurecard-one'){
    
    setfeaturecardOne(res[i].content)
  }
  if(res[i].title=='featurecard-two'){
   
    setfeaturecardTwo(res[i].content)
  }
  if(res[i].title=='featurecard-three'){
  
    setfeaturecardThree(res[i].content)
  }
  if(res[i].title=='featurecard-four'){
 
    setfeaturecardFour(res[i].content)
  }
  if(res[i].title=='featurecard-five'){
    setfeaturecardFive(res[i].content)
  }
  if(res[i].title=='featurecard-six'){
    setfeaturecardSix(res[i].content)
  }   
  if(res[i].title=='roadmap-section'){
    setroadmapsection(res[i].content)
  } 
  if(res[i].title=='roadmap-sectionone'){
    setroadmapsectionOne(res[i].content)
  }
  if(res[i].title=='Tokenomics'){
    setTokenomics(res[i].content)
  }
  if(res[i].title=='Tokenomics-one'){
    setTokenomicsOne(res[i].content)
  }
  if(res[i].title=='Tokenomics-two'){
    setTokenomicsTwo(res[i].content)
  }
  if(res[i].title=='Tokenomics-three'){
    setTokenomicsThree(res[i].content)
  }
  if(res[i].title=='get-in-touch'){
    setGetintouch(res[i].content)
  }
  if(res[i].title=='contact-us'){
    setContact(res[i].content)
  }
  
   }
 
    
  }
  const getsettings= async()=>{
   var countdowndata =  await gethomesettings()
   
  if(countdowndata&&countdowndata.userValue&&countdowndata.userValue.data){
    console.log(countdowndata.userValue.data,'countdoen')
    setcountdownDate(countdowndata.userValue.data.startTimeStamp)

   
  }
  
  }
 

   async function Spideybardervalue(){
     try{
      if (!window.ethereum) {
        toastAlert("OOPS!..connect Your Wallet",'balance');
            return false;
      }
      var web3             = new Web3(window.ethereum);
      var SwappairContract = new web3.eth.Contract(Swappair,config.SwappairAddress);

      const {
        _reserve0,_reserve1
      } =await SwappairContract.methods.getReserves().call();

      var tokenPrice = parseFloat(_reserve1)/parseFloat(_reserve0);
      Set_Swapamt(tokenPrice);
      //EthtoUsd()
     }catch(err){}
   }

    async function EthtoUsd(){
      try{
        if (!window.ethereum) {
          toastAlert("OOPS!..connect Your Wallet",'balance');
              return false;
        }
        var web3             = new Web3(config.Rpcurl);
        var EthtoUsdContract = new web3.eth.Contract(EthtoUsdABI,config.EthtoUsdAddress);
        const usdval         = await EthtoUsdContract.methods.latestAnswer().call();
        const decimalval     = await EthtoUsdContract.methods.decimals().call();
        var final            = usdval/100000000;
        var val              = final/swaptamt;
        Set_Usdamt(final)
      }catch(err){
        console.log("EthtoUsdABI_err",err)
      }
    }
  // Banner Countdown Timer

  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
   
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Minutes</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Seconds</span></span>
      </div>
    );
  }; 
 


  const { ...rest } = props;

  useEffect(() => {
    getsettings();
    getdata();
   // Spideybardervalue();
    setValidateError(validation(formValue))
   },[formValue]);
  return (
    <div className="header_home">
      <ScrollToTopOnMount/>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "dark",
        }}
        {...rest}
      />

      <div className="page-header homeBannerBG" name="home">
        <div className={classes.container + " homepage_container"} name="Home" id="Home">
          <GridContainer className="mt-4">
            <GridItem sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000">
              
              {homeData?<div dangerouslySetInnerHTML={{ '__html': homeData }} />:'no content'}
               
              {/* <div className="homeBannerContent">
                <h2><span>Spidey Barter</span> is not only an illustrative development yet to be released,<span>but a
certified project that is promising great results with already released outcomes in
the cryptocurrency market.</span> Since it is part of the Bitflix core, this project is aimed to
be a helpful electronic assistant that will fight out all scams,  <span>malware's, and other odd
functionalities that are conditioned to destroy wilts causing lots.</span> </h2>
             
                <Button className="banner_btn mt-3"><a href="https://exchange.spideybarter.com/#/swap" target="_blank">Buy Token Now <i class="bi bi-arrow-right ml-2"></i></a></Button>
              </div> */}
            </GridItem>
            <GridItem sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000">
            {homebannerimg?<div dangerouslySetInnerHTML={{ '__html': homebannerimg }} />:'no content'}  
              {/* <div className="homeBannerImg">
                <img src={require("../assets/images/banner_img.png")} className="img-fluid mr-1" alt="Banner" />
              </div> */}
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="main">
        {/* Solution Section */}
 
        <section className="section solution_section">
          <div className="container">
            <GridContainer className="text-center">
              <GridItem sm={12} md={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                {/* <h1 className="main_title main_title_center">Meet Our Solution For You</h1>
                <p>We are here to help out to recover your looses in any crypto ponzi. Who ever lost there crypto in Ponzi, come to us we will pay u back. If you are coming up with own token or blockchain based project and giving airdrop for new community, come to us we will give you user you participate in pool to help out this war against crypto ponzi. If you are donater participate in pool and let earn your donation. This is we are here for Spidey barter.</p> */}
               {solutionsection?<div dangerouslySetInnerHTML={{ '__html': solutionsection }} />:'no content'} 
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-3">
              <GridItem md={3} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
              <div dangerouslySetInnerHTML={{ '__html': solutionsectionOne }} />
                {/* <div className="solution_panel">
                  <img src={require("../assets/images/icon_01.png")}  alt="Icon" />
                  <h2>Who has beenCheated</h2>
                  <Link to="/whohascheated">View more...</Link>
                </div> */}
              </GridItem>
              <GridItem md={3} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
              <div dangerouslySetInnerHTML={{ '__html': solutionsectionTwo }} /> 
                {/* <div className="solution_panel">
                  <img src={require("../assets/images/icon_02.png")}  alt="Icon" />
                  <h2>Scam <br/> Company</h2>
                  <Link to="/scam_company">View more...</Link>
                </div> */}
              </GridItem>
              <GridItem md={3} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
              <div dangerouslySetInnerHTML={{ '__html': solutionsectionThree }} /> 
                {/* <div className="solution_panel">
                  <img src={require("../assets/images/icon_03.png")}  alt="Icon" />
                  <h2>Donate / Contributers</h2>
                  <Link to="/donate_contributors">View more...</Link>
                </div> */}
              </GridItem>
              <GridItem md={3} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
              <div dangerouslySetInnerHTML={{ '__html': solutionsectionFour }} /> 
                {/* <div className="solution_panel">
                  <img src={require("../assets/images/icon_04.png")}  alt="Icon" />
                  <h2>Investor Farmer Liquidity</h2>
                  <a href="https://exchange.spideybarter.com/#/add/BNB/0x5F1cBBE0C9963c83bf046F1A53ECe316ea6E8cC8"  target="_blank">View more...</a>
                </div> */}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* About Section */}
        <section className="section about_section" name="about">
          <div className="container">
            <GridContainer>
              <GridItem sm={6} md={6} data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': aboutsectionImage }} /> 
                {/* <img src={require("../assets/images/about_img.png")}  alt="Icon" className="img-fluid" /> */}
              </GridItem>
              <GridItem sm={6} md={6} data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': aboutsectionContent }} /> 
                {/* <h1 className="main_title">Using Spider Barter by Following the Simple Three-step Guideline</h1>
                <h3>Whether you want to give the platform a try, or you want to go beyond old
methods, you are welcome to run Spidey Barter. It is easy to know how it works since
the project is actively working in the BSC.
</h3>
                <p className="mb-4">1. Reveal the dead project in which you put your money, so the system can
track transactions and get a record of all pending rewards.</p>
<p className="mb-4">2. Apply for the security layer so you won't be again involved in losses. All
transactions are made publicly.</p>
<p className="mb-4">3. Wait for confirmation and provide your address. Never give any other data to
unknown servers. The system won't ask you for your private keys.
In the end, you'll discover that working with Spidey Barter is better than
walking into the Blockchain world by yourself. Just give it time to rescue your
money, the rest will be demonstrated in the path.</p>
                <Button className="banner_btn mt-3"><Link to="/about">Read More <i class="bi bi-arrow-right ml-2"></i></Link></Button> */}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* Features Section */}
        <section className="section features_section" name="features">
          <div className="container">
            <GridContainer className="text-center">
              <GridItem sm={12} md={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                     <div dangerouslySetInnerHTML={{ '__html': featuresectionContent }} /> 
                {/* <h1 className="main_title main_title_center">Features</h1>
                <p>No 1 AMM dex on mutliblockchain. Eth and smart chain. Stake, Farm, Pool, Biggest NFT marketplace, Never loosing lottery.</p> */}
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-3">
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
                <div dangerouslySetInnerHTML={{ '__html': featurecardOne }} /> 
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_01.png")}  alt="Icon" />
                  <h2>Your key to the Cryptocurrency World</h2>
                  <p>Access decentralized finance (cryptocurrency) applications with complete blockchain compatibility</p>
                </div> */}
              </GridItem>
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
              <div dangerouslySetInnerHTML={{ '__html': featurecardTwo }} />
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_02.png")}  alt="Icon" />
                  <h2>One-Stop Access</h2>
                  <p>No more switching between multiple apps</p>
                </div> */}
              </GridItem>
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
              <div dangerouslySetInnerHTML={{ '__html': featurecardThree }} />
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_03.png")}  alt="Icon" />
                  <h2>User Experience Focused</h2>
                  <p>Easily buy, store, swap, send, and receive any cryptocurrency on a sleek and simple interface.</p>
                </div> */}
              </GridItem>
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800">
              <div dangerouslySetInnerHTML={{ '__html': featurecardFour }} />
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_04.png")}  alt="Icon" />
                  <h2>Reliably Stable</h2>
                  <p>We maintain the highest standards of financial security compliance</p>
                </div> */}
              </GridItem>
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000">
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_05.png")}  alt="Icon" />
                  <h2>Interchain & multi-chain compatibility</h2>
                  <p>Effortlessly swap digital assets between chains</p>
                </div> */}
                  <div dangerouslySetInnerHTML={{ '__html': featurecardFive }} />
              </GridItem>
              <GridItem md={4} sm={6} data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200">
              <div dangerouslySetInnerHTML={{ '__html': featurecardSix}} />
                {/* <div className="solution_panel features_panel">
                  <img src={require("../assets/images/feature_icon_06.png")}  alt="Icon" />
                  <h2>Built on Binance Smart Chain</h2>
                  <p>Keep more of your assets in your wallet.</p>
                </div> */}
              </GridItem>
            </GridContainer>
          </div>
        </section>  

        {/* Roadmap Section */}
        <section className="roadmap_section section" name="roadmap">
          <div className="container">
            <GridContainer className="text-center">
              <GridItem sm={12} md={8} lg={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
            <div dangerouslySetInnerHTML={{ '__html': roadmapsection }} />
                {/* <h1 className="main_title main_title_center pb-2">Road Map</h1>
                <p>Nullam convallis imperdiet velit, eu feugiat sem scelerisque a. Donec suscipit, velit vel commodo finibus, nisi diam scelerisque lacus, in fermentum orci diam ac turpis. Donec eget auctor diam.</p> */}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
              <div dangerouslySetInnerHTML={{ '__html': roadmapsectionOne }} />
                {/* <nav class="roadmapYear mt-5" data-aos="fade-up" data-aos-duration="2000">
                  <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-year2016-tab" data-toggle="tab" href="#nav-year2016" role="tab" aria-controls="nav-year2016" aria-selected="true">2016</a>
                    <a class="nav-item nav-link" id="nav-year2017-tab" data-toggle="tab" href="#nav-year2017" role="tab" aria-controls="nav-year2017" aria-selected="false">2017</a>
                    <a class="nav-item nav-link" id="nav-year2018-tab" data-toggle="tab" href="#nav-year2018" role="tab" aria-controls="nav-year2018" aria-selected="false">2018</a>
                    <a class="nav-item nav-link" id="nav-year2019-tab" data-toggle="tab" href="#nav-year2019" role="tab" aria-controls="nav-year2019" aria-selected="false">2019</a>
                    <a class="nav-item nav-link" id="nav-year2020-tab" data-toggle="tab" href="#nav-year2020" role="tab" aria-controls="nav-about" aria-selected="false">2020</a>
                    <a class="nav-item nav-link" id="nav-year2021-tab" data-toggle="tab" href="#nav-year2021" role="tab" aria-controls="nav-about" aria-selected="false">2021</a>
                  </div>
                </nav>
                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent" data-aos="fade-up" data-aos-duration="2000">
                  <div class="tab-pane fade show active" id="nav-year2016" role="tabpanel" aria-labelledby="nav-year2016-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2016</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2016</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2016</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2016</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2016</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="nav-year2017" role="tabpanel" aria-labelledby="nav-year2017-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2017</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2017</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2017</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2017</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2017</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="nav-year2018" role="tabpanel" aria-labelledby="nav-year2018-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2018</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2018</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2018</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2018</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2018</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="nav-year2019" role="tabpanel" aria-labelledby="nav-year2019-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2019</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2019</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2019</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2019</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2019</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="nav-year2020" role="tabpanel" aria-labelledby="nav-year2019-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2020</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2020</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2020</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2020</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2020</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="nav-year2021" role="tabpanel" aria-labelledby="nav-year2019-tab">
                    <ul className="roadMapList">
                      <li>
                        <h4>January 2021</h4>
                        <p>Start of the ICO Token Platform Development</p>
                      </li>
                      <li>
                        <h4>March 2021</h4>
                        <p>Launched of mobile Apps</p>
                      </li>
                      <li>
                        <h4>July 2021</h4>
                        <p>Partnership for the future EcoSystem</p>
                      </li>
                      <li>
                        <h4>September 2021</h4>
                        <p>Priority opening for Token holder</p>
                      </li>
                      <li>
                        <h4>November 2021</h4>
                        <p>Start Token Sale Round</p>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* Token Section */}
        <section className="token_section section" name="token">
          <div className="container">
            <GridContainer className="text-center">
              <GridItem sm={12} md={8} lg={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': Tokenomics }} />
                {/* <h1 className="main_title main_title_center pb-2">Tokenomics</h1>
                <p>Phasellus quis lacinia purus. Sed vitae lacus volutpat enim iaculis congue in eu ipsum. Cras fringilla vestibulum aliquet. Sed gravida ligula augue. </p> */}
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-4 align-items-center">
              <GridItem md={3} data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': TokenomicsOne }} />
                {/* <div class="token_details">
                  <label>Starting Time:</label>
                  <p>Jun 24, 2021 (Thu 10:00 AM)</p>
                </div>
                <div class="token_details">
                  <label>Ending Time:</label>
                  <p>Aug 30, 2021 (Mon 12:00 PM)</p>
                </div>
                <div class="token_details">
                  <label>Tokens exchange rate:</label>
                  <p>1ETH = {usdt/swaptamt} Spidey barder, 1 Spidey barder = {swaptamt.toFixed(8)} USD</p>
                </div> */}
              </GridItem>
              <GridItem md={6} data-aos="fade-up" data-aos-duration="2000">
                <div class="coundown_panel">
                  <div className="coundown_timer">
                   {console.log(typeof(countdownDate),'countdownDate')}
                  
                        <Countdown date={new Date(countdownDate)}renderer={renderer} />
                        
                       
                  </div>
                  {/* <div dangerouslySetInnerHTML={{ '__html': TokenomicsTwo }} /> */}
                  <div class="progress custom_progress">
                    <div class="progress-bar progress-bar-striped gradient" role="progressbar" aria-valuenow="46" aria-valuemin="0" aria-valuemax="100" style={{width:"46%"}}> 46% </div>
                    <span class="progress_label bg-white" style={{left:"30%"}}> <strong> 46,000 BCC </strong></span>
                    <span class="progress_label bg-white" style={{left:"75%"}}> <strong> 90,000 BCC </strong></span>
                    <span class="progress_min_val">Sale Raised</span>
                    <span class="progress_max_val">Soft-caps</span>
                  </div>
                  <div className="text-center">
                    <Button className="banner_btn mt-3"><Link to="/">Buy Token <i class="bi bi-arrow-right ml-2"></i></Link></Button>
                  </div>
                </div>
              </GridItem>
              <GridItem md={3} data-aos="fade-up" data-aos-duration="2000">
                        <div dangerouslySetInnerHTML={{ '__html': TokenomicsThree }} />
                {/* <div class="token_details">
                  <label>Low - High 24h:</label>
                  <p>$ 6,455.83 - $ 7,071.42</p>
                </div>
                <div class="token_details">
                  <label>Number of tokens for sale:</label>
                  <p>850,000 BCC (8%)</p>
                </div>
                <div class="token_details">
                  <label>Acceptable Currency:</label>
                  <p>BNB,Spidey barter,ETH,BUSD</p>
                </div> */}
              </GridItem>
            </GridContainer>
          </div>
        </section>

        {/* Get in Touch Section */}
        <section className="gettouch_section section" name="contact">
          <div className="container">
            <GridContainer className="text-center">
              <GridItem sm={12} md={8} lg={8} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': Getintouch }} />
                {/* <h1 className="main_title main_title_center pb-2">Get In Touch!</h1>
                <p>Phasellus quis lacinia purus. Sed vitae lacus volutpat enim iaculis congue in eu ipsum. Cras fringilla vestibulum aliquet. Sed gravida ligula augue. </p> */}
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-4">
              <GridItem md={6} data-aos="fade-up" data-aos-duration="2000">
              <div dangerouslySetInnerHTML={{ '__html': contact }} />
                {/* <div className="solution_panel gettouch_panel">
                  <p>Our office is located in a beautiful building and garden</p>
                  <div className="gettouch_panel_div">
                    <img src={require("../assets/images/icon_location.png")}  alt="Icon" />
                    <div>
                      <h4>Address</h4>
                      <h4>Kemp House 160 City Road,</h4>
                      <h4>London EC1V2VX</h4>
                    </div>
                  </div>
                  <div className="gettouch_panel_div">
                    <img src={require("../assets/images/icon_phone.png")}  alt="Icon" />
                    <div>
                      <h4>Phone</h4>
                      <a href="tel:+23 0123 4567">+23 0123 4567</a>
                    </div>
                  </div>
                  <div className="gettouch_panel_div">
                    <img src={require("../assets/images/icon_email.png")}  alt="Icon" />
                    <div>
                      <h4>Email-id</h4>
                      <a href="mailto:Yourmail@gmail.com">Yourmail@gmail.com</a>
                    </div>
                  </div>
                </div> */}
              </GridItem>
              <GridItem md={6} data-aos="fade-up" data-aos-duration="2000">
                <form  >
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <input type="text" class="primary_inp" id="name" placeholder="Name"  onChange={handleChange} onBlur={handleBlur}/>
                      {toched.name && validateError.name&& (
            <p className="error-message" style={{color:"red"}}>{validateError.name}</p>
          )}
                    </div>
                    <div class="form-group col-md-12">
                      <input type="email" class="primary_inp" id="email" placeholder="Email address"  onChange={handleChange} onBlur={handleBlur}/>
                      {toched.email && validateError.email&& (
            <p className="error-message" style={{color:"red"}}>{validateError.email}</p>
          )}
                    </div>
                    <div class="form-group col-md-12">
                      <textarea rows="5" placeholder="Message"  id="messages" class="primary_inp"  onChange={handleChange} onBlur={handleBlur}></textarea>
                      {toched.messages && validateError.messages&& (
            <p className="error-message"style={{color:"red"}}>{validateError.messages}</p>
          )}
                    </div>
                  </div>
                  <Button  disabled={!isEmpty(validateError)} onClick={handleSubmit} className="banner_btn mt-3">Submit</Button>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
